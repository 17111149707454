import React from 'react';
import { DailyAgendaWeeklyPickerLayoutViewModel } from '../../../ViewModel/bodyViewModel/bodyViewModel.types';
import ErrorNotification from '../../ErrorNotification/ErrorNotification';
import TimePicker from '../TimePicker/TimePicker';
import WeeklyDatePicker from '../DatePicker/WeeklyDatePicker/WeeklyDatePicker';
import { DataHooks } from './constants';
import { AgendaSlot } from '../../AgendaSlot/AgendaSlot';
import { classes, st } from './DailyAgendaWeeklyPickerLayout.st.css';
import { Text, TextButton } from 'wix-ui-tpa/cssVars';
import {
  AgendaSlot as AgendaSlotType,
  AgendaSlotsViewModel,
} from '../../../ViewModel/agendaSlotsViewModel/agendaSlotsViewModel';
import { useCalendarActions } from '../../../Hooks/useCalendarActions';
import {
  WidgetComponents,
  WidgetElements,
} from '../../../../../utils/bi/consts';
import { useSettings } from '@wix/tpa-settings/react';
import settingsParams from '../../../settingsParams';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';

const DailyAgendaSlotSelection = ({
  agendaSlots,
  showAllText,
  numberOfSlotsToDisplay,
}: AgendaSlotsViewModel) => {
  const [slotsToShow, setSlotsToShow] = React.useState<AgendaSlotType[]>([]);
  const { onElementClicked } = useCalendarActions();

  const showAllSlots = () => {
    setSlotsToShow(agendaSlots);
    onElementClicked(
      WidgetComponents.TIME_PICKER,
      WidgetElements.SHOW_ALL_SESSIONS_BUTTON,
    );
  };

  const shouldDisplayShowAllText =
    numberOfSlotsToDisplay && slotsToShow.length < agendaSlots.length;

  React.useEffect(() => {
    setSlotsToShow(agendaSlots.slice(0, numberOfSlotsToDisplay));
  }, [numberOfSlotsToDisplay, agendaSlots]);

  return (
    <>
      <div className={classes.slotsContainer}>
        {slotsToShow.map((slot) => (
          <AgendaSlot slot={slot} key={slot.id} />
        ))}
      </div>

      {shouldDisplayShowAllText && (
        <div className={classes.showAllButtonContainer}>
          <TextButton
            data-hook={DataHooks.ShowAllButton}
            className={classes.showAllButton}
            onClick={showAllSlots}
          >
            {showAllText}
          </TextButton>
        </div>
      )}
    </>
  );
};

export interface DailyAgendaWeeklyPickerLayoutProps {
  viewModel: DailyAgendaWeeklyPickerLayoutViewModel;
  errorNotificationText: string;
}

const DailyAgendaWeeklyPickerLayout: React.FC<
  DailyAgendaWeeklyPickerLayoutProps
> = ({
  viewModel: { datePickerViewModel, timePicker },
  errorNotificationText,
}) => {
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const { experiments } = useExperiments();
  const {
    status,
    formattedSelectedDate,
    accessibility,
    noAvailableSlotsViewModel: { noSessionsOfferedText },
    slots,
  } = timePicker;

  const NoAvailableSlots = () => (
    <Text data-hook={DataHooks.NoAvailableSlots} className={classes.noSlots}>
      {noSessionsOfferedText}
    </Text>
  );

  return (
    <div data-hook={DataHooks.Layout}>
      <ErrorNotification errorText={errorNotificationText} />
      <div
        className={st(classes.root, {
          isMobile,
          withSideBorders: settings.get(settingsParams.sideBordersVisibility),
        })}
      >
        <WeeklyDatePicker viewModel={datePickerViewModel} />
        <TimePicker
          accessibility={accessibility}
          status={status}
          date={formattedSelectedDate}
          availableSlotsComponent={<DailyAgendaSlotSelection {...slots} />}
          noAvailableSlotsComponent={<NoAvailableSlots />}
        />
      </div>
    </div>
  );
};

export default DailyAgendaWeeklyPickerLayout;
